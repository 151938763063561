import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import ToolLoanSummary from '../ToolLoanSummary/LoanSummary';
import ToolCalendar from '../ToolCalendar/Calendar';
import ToolCalculator from '../ToolCalculator/Calculator';
import CampaignBlog from '../CampaignBlog/Blog';
import { styles } from './Styles';
import { useNavigation } from '@react-navigation/native'; // Import navigation hook

const ControlPanel = () => {
  const navigation = useNavigation(); // Access navigation object
  const [isReload, setIsReload] = useState(false);

  useEffect(() => {
    // Update isReload when navigation state changes (screen switch)
    const unsubscribe = navigation.addListener('state', () => {
      setIsReload(true);
    });

    return unsubscribe; // Cleanup function to prevent memory leaks
  }, [navigation]); // Dependency array for useEffect

return (
    <View style={styles.canvas}>
      <View style={[styles.container]}>
        <ToolCalendar />
        {/* <ToolLoanSummary /> */}
        <CampaignBlog />
        {/* <ToolCalculator /> */}
        {/* ^^^^ TODO there should be a tools tab */}
      </View>
    </View>
  );
};

export default ControlPanel;
