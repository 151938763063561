import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  warningText: {
    color: '#FFC666',
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  errorText: {
    color: '#FFC666',
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 20,
    alignContent: 'center',
  },
  canvas: {
    backgroundColor: "#0E402D",
    backgroundImage: "linear-gradient(to top, #749D47, #0E402D )",
    height: "100%",
    overflowY: "auto",
    zIndex: "1",
    paddingTop: 40,
    paddingLeft: 31,
    paddingRight: 31,
    // paddingBottom: 64,
  },
  modalCanvas: {
    backgroundColor: "#0E402D",
    // backgroundImage: "linear-gradient(to top, #749D47, #0E402D )",
    height: "100%",
    overflowY: "auto",
    zIndex: "1",
    paddingTop: 40+64,
    paddingLeft: 31,
    paddingRight: 31,
    paddingBottom: 64,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    color: 'white',
    width: '100%',
    maxWidth: 720,
    marginBottom: 20,
    marginTop: 20,
    margin: 'auto',
    textAlign: 'center',
  },
  frequencytitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: 'white',
    width: '100%',
    margin: 'auto',
    marginBottom: 20,
    marginTop: 0,
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: 'white',
    width: '100%',
    margin: 'auto',
    marginBottom: 0,
    marginTop: 20,
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  successLoanCard: {
    margin: "auto",
    marginTop: 0,
    width: "calc(100% - 18px)",
    maxWidth: 300,
    paddingHorizontal: 20,
    borderRadius: 12,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    shadowColor: "#000",
    shadowOffset: {
      width: 3,
      height: 3,
    },
    shadowOpacity: 0.20,
    shadowRadius: 3.84,
    elevation: 1,
    padding: 20,
    marginBottom: 40,
  },
  input: {
    display: 'flex',
    alignItems: 'center',
    height: 40,
    borderColor: 'white',
    borderWidth: 0,
    marginBottom: 20,
    padding: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.10)',
    color: 'white',
    fontWeight: '400',
    fontSize: 14,
    borderRadius: 12,
  },
  label: {
    fontSize: 14,
    marginBottom: 5,
    color: 'white',
  },
  labelDescription: {
    fontSize: 10,
    marginBottom: 5,
    color: 'white',
  },
  button: {
    backgroundColor: 'rgba(256, 256, 256, 0.10)',
    padding: 20,
    alignItems: 'center',
    marginTop: 0,
    marginBottom: 0,
    width: '100%',
    maxWidth: 256,
    borderRadius: 12,
    margin: 'auto'
  },
  buttonText: {
    color: 'white',
    fontSize: 16,
    fontWeight: '500',
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#0E402D',
    padding: 60,
  },
  calendarContainer: {
    backgroundColor: '#0E402D',
    borderRadius: 0,
    width: '90%',
    maxWidth: 400,
    height: 500,
    position: 'relative',
  },
  container: {
    margin: "auto",
    width: "100%",
    borderRadius: 12,
    overflow: 'hidden',
  },
  loanCard: {
    margin: "auto",
    marginTop: 0,
    width: "calc(100% - 18px)",
    maxWidth: 768,
    padding: 20,
    paddingTop: 4,
    borderRadius: 12,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    shadowColor: "#000",
    shadowOffset: {
      width: 3,
      height: 3,
    },
    shadowOpacity: 0.20,
    shadowRadius: 3.84,
    elevation: 1,
    marginBottom: 40,
  },
  closeButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(256, 256, 256, 0.10)',
    padding: 20,
    alignItems: 'center',
    width: '100%',
    maxWidth: 256,
    borderRadius: 12,
    margin: 'auto',
  },
  closeButtonText: {
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold',
  },
  successText: {
    color: 'white',
    flex: 1,
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 'bold',
    margin: "auto",
    textDecorationLine: 'none'
  },
  frequencyOptionText: {
    color: 'white',
    flex: 1,
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 'bold',
    margin: "auto",
    marginTop: 10,
    marginBottom: 10,
    textDecorationLine: 'none',
    border: 'none',
    padding: 10,
    alignItems: 'center',
    width: '100%',
    maxWidth: 256,
    borderRadius: 12,
    margin: 'auto'
  },
  modalLeft: {
    color: 'white',
    flex: 1,
    textAlign: 'left',
    alignItems: 'left',
    fontSize: 14,
    // fontWeight: 'bold',
    textDecorationLine: 'none',
    border: 'none',
    padding: 10,
    width: '100%',
    borderRadius: 12,
  },
  modalRight: {
    color: 'white',
    flex: 1,
    textAlign: 'right',
    alignItems: 'right',
    fontSize: 14,
    // fontWeight: 'bold',
    textDecorationLine: 'none',
    border: 'none',
    padding: 10,
    width: '100%',
    borderRadius: 12,
  },
  calcContainer: {
    backgroundColor: 'red',
    width: '100%',
    borderRadius: 12,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    shadowColor: "#000",
    shadowOffset: {
      width: 3,
      height: 3,
    },
    shadowOpacity: 0.20,
    shadowRadius: 3.84,
    elevation: 1,
    padding: 20,
    marginBottom: 40,
    maxWidth: 720,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: "100%",
    maxWidth: 600,
    margin: 'auto',
  },
  modalButton: {
    alignItems: 'center',
    padding: 20,
    width: '100%',
    maxWidth: 256,
    height: 59,
    flex: 1,
    backgroundColor: 'rgba(256, 256, 256, 0.10)',
    borderRadius: 12,
    margin: 8,
    marginTop: 20,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    fontSize: 16,
    marginTop: 2,
    borderRadius: 12,
    flexDirection: 'row',
    justifyContent: 'space-between',
},
});
