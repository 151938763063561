import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
    container: {
      margin: "auto",
      width: "calc(100% - 18px)",
      maxWidth: 768,
      padding: 20,
      paddingTop: 4,
      borderRadius: 12,
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      shadowColor: "#000",
      shadowOffset: {
        width: 3,
        height: 3,
      },
      shadowOpacity: 0.20,
      shadowRadius: 3.84,
      elevation: 1,
      marginBottom: 40,
    },
    day: {
        width: 32,
        height: 32,
        alignItems: 'center',
        justifyContent: 'center',
    },
    dayText: {
        fontSize: 14,
    },
});
