import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
    container: {
        margin: "auto",
        width: "calc(100% - 18px)",
        maxWidth: 768,
        padding: 20,
        borderRadius: 12,
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        shadowColor: "#000",
        shadowOffset: {
            width: 3,
            height: 3,
        },
        shadowOpacity: 0.20,
        shadowRadius: 3.84,
        elevation: 1,
        marginBottom: 40,
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        color: 'white',
        width: '100%',
        maxWidth: 720,
        marginBottom: 20,
        margin: 'auto',
        textAlign: 'center',
    },
    input: {
        display: 'flex',
        alignItems: 'center',
        height: 40,
        borderColor: 'white',
        borderWidth: 0,
        marginBottom: 20,
        padding: 20,
        backgroundColor: 'rgba(0, 0, 0, 0.10)',
        color: 'white',
        borderRadius: 12,
        fontSize: 14,
    },
    result: {
        padding: 20,
        paddingTop: 0,
    },
    resultText: {
        fontSize: 14,
        color: 'white',
        fontWeight: '800',
    },
    resultValue: {
        fontSize: 14,
        color: 'white',
        fontWeight: '800',
    },
    resultRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    label: {
        fontSize: 14,
        marginBottom: 5,
        color: 'white',
    },
    picker: {
        width: 'calc( 100% - 16px )',
        height: 40,
        borderWidth: 0,
        backgroundColor: 'transparent',
        color: 'white',
        paddingLeft: 16,
        fontSize: 14,
    },
    pickerContainer: {
        backgroundColor: 'rgba(0, 0, 0, 0.10)',
        borderRadius: 12,
        marginBottom: 20,
    },
    switchContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
    },
    button: {
        backgroundColor: 'rgba(256, 256, 256, 0.10)',
        alignItems: 'center',
        padding: 20,
        width: '100%',
        flex: 1,
        borderRadius: 12,
    },
    buttonText: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: 14,
    },
    buttonGroup: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 10,
    },
});
