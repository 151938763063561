import React, { useEffect, useState } from 'react';
import { ScrollView, View, Image, Text, Linking, TouchableOpacity, useWindowDimensions } from 'react-native';
import axios from 'axios';
import { styles } from './Styles';
import { ModuleLoading } from '../Loading/Loading';

const CampaignBlog = () => {
  const [rssData, setRssData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const windowWidth = useWindowDimensions().width;

  const apiUrl = 'https://api.bharo.org/api';

  const fetchRSSData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/fetch-rss`);
      if (!response.data.posts || response.data.posts.length === 0) {
        throw new Error('No data received');
      }
      setRssData(response.data.posts);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching RSS data:', error);
      setError('An error has occurred when fetching the Learning Hub posts.');
      setTimeout(fetchRSSData, 5000); // Retry after 5 seconds
    }
  };

  useEffect(() => {
    fetchRSSData();
  }, []);

  return (
    <View style={styles.container}>
      {loading ? (
        <ModuleLoading />
      ) : (
        <>
          <Text style={styles.title}>New Blog Posts</Text>
          <ScrollView>
            {rssData.slice(0, 3).map((item, index) => {
              return (
                <View key={index} style={styles.itemRow}>
                  {item.image && (
                    <TouchableOpacity onPress={() => Linking.openURL(item.link)}>
                      <Image
                        source={{ uri: item.image }}
                        style={{
                          flex: 1,
                          aspectRatio: 16 / 9,
                          marginBottom: 20,
                          borderRadius: 12,
                        }}
                      />
                    </TouchableOpacity>
                  )}
                  <TouchableOpacity onPress={() => Linking.openURL(item.link)}>
                    <Text style={styles.cellTitle}>{item.title}</Text>
                  </TouchableOpacity>
                  <Text style={styles.cellTime}>
                    {new Date(item.pubDate).toLocaleString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: '2-digit'
                    })}
                  </Text>
                  <View style={styles.cellDescription}>
                    <Text style={styles.chunk}>
                      {windowWidth > 600 ? item.description : item.description.split(/[.!?]/)[0]}.
                    </Text>
                  </View>
                  <View style={styles.buttonGroup}>
                    <TouchableOpacity style={styles.finalButtonWrapper} onPress={() => Linking.openURL(item.link)}>
                      <Text style={styles.buttonText}>Read More</Text>
                    </TouchableOpacity>
                  </View>
                  <View
                    style={{
                      borderBottomColor: 'black',
                      borderBottomWidth: 44,
                      opacity: 0,
                    }}
                  />
                </View>
              );
            })}
            <View style={[styles.buttonGroup, { marginTop: -44 }]}>
              <TouchableOpacity style={styles.finalButtonWrapper} onPress={() => Linking.openURL('https://www.bharo.org/financial-learning-hub')}>
                <Text style={styles.buttonText}>
                  More Articles
                </Text>
              </TouchableOpacity>
            </View>
          </ScrollView>
        </>
      )}
    </View>
  );
};

export default CampaignBlog;
