import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  canvas: {
    backgroundColor: "#0E402D",
    backgroundImage: "linear-gradient(to top, #749D47, #0E402D )",
    height: "100%",
    overflowY: "auto",
    zIndex: "1",
    paddingTop: 40,
    paddingLeft: 31,
    paddingRight: 31,
    paddingBottom: 64,
  },
  loanCard: {
    margin: "auto",
    marginTop: 0,
    width: "calc(100% - 18px)",
    maxWidth: 768,
    paddingHorizontal: 20,
    borderRadius: 12,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    shadowColor: "#000",
    shadowOffset: {
      width: 3,
      height: 3,
    },
    shadowOpacity: 0.20,
    shadowRadius: 3.84,
    elevation: 1,
    padding: 20,
    marginBottom: 40,
  },
  errorText: {
    color: 'white',
    flex: 1,
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 'bold',
    margin: "auto",
    textDecorationLine: 'none'
  },
  errorCard: {
    margin: "auto",
    marginTop: 0,
    width: "calc(100% - 18px)",
    maxWidth: 300,
    paddingHorizontal: 20,
    borderRadius: 12,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    shadowColor: "#000",
    shadowOffset: {
      width: 3,
      height: 3,
    },
    shadowOpacity: 0.20,
    shadowRadius: 3.84,
    elevation: 1,
    padding: 20,
    marginBottom: 40,
  },
  input: {
    color: 'white',
    marginBottom: 10,
    fontSize: 14,
  },
  button: {
    backgroundColor: 'rgba(256, 256, 256, 0.10)',
    alignItems: 'center',
    padding: 20,
    width: '100%',
    flex: 1,
    borderRadius: 12,
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 14,
  },
  buttonGroup: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
    width: '100%',
  },
  managePermissionsTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: 'white',
    width: '100%',
    maxWidth: 720,
    margin: 'auto',
    marginTop: 20,
    marginBottom: 20,
    textAlign: 'center',
  },
  upcomingTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: 'white',
    width: '100%',
    maxWidth: 720,
    marginBottom: 20,
    margin: 'auto',
    textAlign: 'center',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    color: 'white',
    width: '100%',
    maxWidth: 720,
    marginBottom: 8,
    margin: 'auto',
    textAlign: 'center',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    fontSize: 16,
    marginTop: 2,
    borderRadius: 12,
  },
  cell: {
    color: 'white',
    flex: 1,
    textAlign: 'center',
    fontSize: 14,
  },
  nameCell: {
    color: 'white',
    flex: 1,
    textAlign: 'left',
    fontSize: 14,
    paddingLeft: 10,
  },
  moneyCell: {
    color: 'white',
    flex: 1,
    textAlign: 'right',
    fontSize: 14,
    paddingLeft: 10,
  },
  headerCell: {
    color: 'white',
    flex: 1,
    textAlign: 'center',
  },
  sortButton: {
    flex: 1,
  },
  sortButtonText: {
    color: 'white',
    fontSize: 14
  },
  sortButtonContent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
