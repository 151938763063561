import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  canvas: {
    backgroundColor: "#0E402D",
    backgroundImage: "linear-gradient(to top, #749D47, #0E402D )",
    height: "100%",
    overflowY: "auto",
    zIndex: "1",
    paddingTop: 40,
    paddingLeft: 31,
    paddingRight: 31,
    // paddingBottom: 64,
    width: "100%",
  },
  container: {
    margin: "auto",
    width: "100%",
    borderRadius: 12,
    overflow: 'hidden',
  },
  text: {
    color: 'white',
    fontSize: 18,
    marginBottom: 10,
  },
  buttonContainer: {
    marginTop: 20,
  },
  pleaseSignIn: {
      fontSize: 18,
      fontWeight: 'bold',
      color: 'white',
      width: '100%',
      maxWidth: 720,
      marginTop: 20,
      marginBottom: 20,
      margin: 'auto',
      textAlign: 'center',
  },
});
