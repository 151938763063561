import React from 'react';
import { View, Text, Linking, TouchableOpacity, Image } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { styles } from './Styles';

const LandingScreen = () => {
  const navigation = useNavigation();

  return (
    <View style={styles.container}>
      <View>
        <TouchableOpacity onPress={() => Linking.openURL('https://bharo.org')}>
          <Image
            source={require("../../assets/Logo/SVG/main-logo-transparent.svg")}
            style={styles.logo}
          />
        </TouchableOpacity>
      </View>
      <View style={styles.buttonGroup}>
        <TouchableOpacity style={styles.button} onPress={() => navigation.navigate("Log In")}>
          <Text style={styles.buttonText}>Log In</Text>
        </TouchableOpacity>
      </View>
      <View style={styles.buttonGroup}>
        <TouchableOpacity style={styles.button} onPress={() => Linking.openURL('https://www.bharo.org/support-hub')}>
          <Text style={styles.buttonText}>
            FAQ/Support
          </Text>
        </TouchableOpacity>
      </View>
      <View style={styles.buttonGroup}>
        <TouchableOpacity style={styles.button} onPress={() => Linking.openURL('https://www.bharo.org/financial-learning-hub')}>
          <Text style={styles.buttonText}>
            Learning Hub
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default LandingScreen;