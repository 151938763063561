import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'top',
    height: "100%",
    padding: 20,
    backgroundImage: "linear-gradient(to top, #749D47, #0E402D )",
  },
  imageContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    display: 'flex',
    width: 300,
    height: 300,
    resizeMode: 'contain',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 16,
    marginBottom: 20,
    fontWeight: 'bold',
    color: 'white',
  },
  loanCard: {
    margin: "auto",
    marginTop: 0,
    padding: 20,
    borderRadius: 24,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    width: '100%',
    maxWidth: 600,
  },
  uploadButton: {
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    borderRadius: '4px',
    cursor: 'pointer',
    backgroundColor: 'rgba(0, 0, 0, 0.10)',
    borderRadius: 24,
    height: 40,
    fontSize: 16,
  },
  mazing: {
    marginRight: 32,
  },
  input: {
    display: 'flex',
    alignItems: 'center',
    height: 40,
    marginBottom: 20,
    padding: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.10)',
    color: 'white',
    fontWeight: '400',
    fontSize: 16,
    borderRadius: 12,
  },
  label: {
    fontSize: 14,
    marginBottom: 5,
    color: 'white',
  },
  buttonGroup: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
    width: '100%',
    maxWidth: 256,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  button: {
    backgroundColor: 'rgba(256, 256, 256, 0.10)',
    alignItems: 'center',
    padding: 20,
    margin: 10,
    marginBottom: 10,
    marginTop: 0,
    flex: 1,
    borderRadius: 12,
    // boxShadow: "3px 3px 3.84px rgba(0, 0, 0, 0.20)",
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 16,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#749D47',
  },
  calendarContainer: {
    backgroundColor: '#749D47',
    borderRadius: 0,
    width: '90%',
    maxWidth: 400,
  },
  closeButton: {
    border: '2px solid white',
    width: '80%',
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 24,
    alignSelf: 'center',
  },
  closeButtonText: {
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold',
  },
});
