import React from 'react';
import { ActivityIndicator, View } from 'react-native';
import { styles } from './Styles';

export const LoadingScreen = () => {
  return (
      <View style={styles.container}>
        <ActivityIndicator size="large" color="#FFC666" />
      </View>
  );
};

export const ModuleLoading = () => {
  return (
      <View style={styles.loading}>
        <ActivityIndicator size="large" color="#FFC666" />
      </View>
  );
};
