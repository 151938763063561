import { useNavigation } from '@react-navigation/native';
import React, { useState, useEffect, useRef } from 'react';
import { View, Text, TextInput, Modal, TouchableOpacity, ScrollView } from 'react-native';
import axios from 'axios';
import { Calendar } from 'react-native-calendars';
import { styles } from './Styles';
import { FontAwesome5 } from '@expo/vector-icons';
import { auth } from '../../firebase';
import { ModuleLoading } from '../Loading/Loading';
import moment from 'moment'; // Install moment.js: npm install moment

const apiUrl = 'https://api.bharo.org/api'

const LoanCreation = () => {
  const navigation = useNavigation();
  const [isCalendarVisible, setCalendarVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEndDateCalendarVisible, setEndDateCalendarVisible] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmissionComplete, setIsSubmissionComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedFrequency, setSelectedFrequency] = useState('');
  const [isFrequencyModalVisible, setFrequencyModalVisible] = useState(false);
  const [isCompoundingFrequencyModalVisible, setCompoundingFrequencyModalVisible] = useState(false);
  const [selectedCompoundingFrequency, setSelectedCompoundFrequency] = useState('');
  const scrollViewRef = useRef();
  const [modalVisible, setModalVisible] = useState(false);

  const frequencyOptions = [
    { label: 'None', value: 0 }, // seconds in a day
    { label: 'Daily', value: 86400000 }, // seconds in a day
    { label: 'Weekly', value: 86400000 * 7 },
    { label: 'Monthly', value: 86400000 * 30.436875 },
    { label: 'Quarterly', value: 86400000 * 30.436875 * 3 },
  ];

  const repaymentFrequencyOptions = [
    { label: 'Daily', value: 86400000 }, // seconds in a day
    { label: 'Weekly', value: 86400000 * 7 },
    { label: 'Monthly', value: 86400000 * 30.436875 },
    { label: 'Quarterly', value: 86400000 * 30.436875 * 3 },
  ];


  const [loanDetails, setLoanDetails] = useState({
    // loanName: '',
    // currency: 'USD',
    // memo: '',
    // principal: '', //this is original loan amount
    // interestRate: '',
    // startDate: '', // TODO check duration between these two and 
    // endDate: '', // TODO check duration between these two
    // paymentSchedule: '',
    // compoundingFrequency: '',

    loanName: 'sadasdasd',
    currency: 'USD',
    memo: 'asdasdasd',
    principal: parseFloat(1231231.12).toFixed(2), //this is original loan amount
    interestRate: parseFloat(1.00).toFixed(2),
    startDate: '06/06/2027', // TODO check duration between these two and 
    endDate: '06/28/2055', // TODO check duration between these two
    paymentSchedule: 86400000,
    compoundingFrequency: 7889238000,

    // validate and that payment frequency is less than total time, and if the user specifies a duration that is
    // like August 6 and Nov 9, it will calculate the number of payments and the amount of each payment per frequency and the remainder will be the last payment

  });

  // useEffect(() => {
  //   if (modalVisible) {
  //     scrollViewRef.current?.scrollTo({ x: 0, y: 0, animated: true });
  //   }
  // }, [modalVisible]);


  // FYI: this clears the loan details when the screen is navigated to so if you set vars for testing... make sure to comment out this useEffect
  useEffect(() => {
    const unsubscribe = navigation.addListener('blur', () => {
      // Clear loanDetails when the user navigates away from this screen
      setIsSubmissionComplete(false);
      setLoanDetails({
        loanName: '',
        currency: 'USD',
        memo: '',
        principal: '',
        interestRate: '',
        startDate: '',
        endDate: '',
        compoundingFrequency: '',
        paymentSchedule: '',
      });
    });

    return unsubscribe;
  }, [navigation]);

  const handleDateChange = (date) => {
    setLoanDetails({ ...loanDetails, startDate: date });
    setCalendarVisible(false);
  };

  const handleEndDateChange = (date) => {
    setLoanDetails({ ...loanDetails, endDate: date });
    setEndDateCalendarVisible(false);
  };

  const handleFrequencyChange = (frequency) => {
    const selectedMs = frequencyOptions.find(option => option.label === frequency).value;
    setLoanDetails({ ...loanDetails, paymentSchedule: selectedMs });
    setSelectedFrequency(frequency);
    setFrequencyModalVisible(false);
  };

  const handleCompoundFrequencyChange = (frequency) => {
    const selectedMs = frequencyOptions.find(option => option.label === frequency).value;

    setLoanDetails({ ...loanDetails, compoundingFrequency: selectedMs });
    setSelectedCompoundFrequency(frequency);
    setCompoundingFrequencyModalVisible(false);
  };

  const validateLoanDetails = (details) => {
    const errors = {};
    const {
      loanName, memo, principal, interestRate,
      compoundingFrequency, startDate, endDate, paymentSchedule
    } = details;

    const isValidNumber = (value) => /^\d+(\/.\d+)?$/.test(value);
    const datePattern = /^\d{2}\/\d{2}\/\d{4}$/; // Updated date pattern to MM/DD/YYYY
    const startDateObj = moment(startDate, 'MM/DD/YYYY'); // Updated date format to MM/DD/YYYY
    const endDateObj = moment(endDate, 'MM/DD/YYYY'); // Updated date format to MM/DD/YYYY
    const totalLoanDuration = moment.duration(endDateObj.diff(startDateObj));


    // Validate loan name length
    if (typeof loanName !== 'string' || loanName.length < 1 || loanName.length > 32) {
      errors.loanName = 'Loan Name must be between 1 and 32 characters';
    }

    // Validate principal
    if (isNaN(parseFloat(principal)) || !isFinite(principal) || principal <= 0) {
      errors.principal = 'Principal must be a valid number greater than zero.';
    }

    // Validate memo length
    if (typeof memo !== 'string' || memo.length > 256) {
      errors.memo = 'Memo must be 256 characters or less.';
    }

    // Validate interest rate
    if (isNaN(parseFloat(interestRate)) || !isFinite(interestRate) || parseFloat(interestRate) < 0) {
      errors.interestRate = 'Nominal Interest Rate must be a valid number';
    }
    if (parseFloat(interestRate) > 0 && compoundingFrequency === 0) {
      errors.interestRate = 'Interest rate must be zero if compounding frequency is none.';
    }

    // Validate start date format
    if (typeof startDate !== 'string' || !datePattern.test(startDate)) {
      errors.startDate = 'Start Date is required in MM/DD/YYYY format';
    }

    // Validate end date format
    if (typeof endDate !== 'string' || !datePattern.test(endDate)) {
      errors.endDate = 'End Date is required in MM/DD/YYYY format';
    } else {
      // Validate end date using moment.js
      const testStartDate = moment(startDate, 'MM/DD/YYYY');
      if (!testStartDate.isValid()) {
        throw new Error('Invalid start date format. Please use MM/DD/YYYY.');
      }
      if (moment(endDate).isBefore(testStartDate)) {
        errors.endDate = 'End Date needs to be after the start date.';
      }
    }
    if (compoundingFrequency > totalLoanDuration.asMilliseconds()) {
      errors.compoundingFrequency = 'Compounding Frequency cannot exceed loan duration.';
    }
    if (paymentSchedule > totalLoanDuration.asMilliseconds()) {
      errors.paymentSchedule = 'Payment schedule cannot exceed loan duration.';
    }
    // Validate compounding frequency
    if (!isValidNumber(compoundingFrequency)) {
      errors.compoundingFrequency = 'Compounding Frequency is required.';
    }

    // Validate payment schedule
    if (!isValidNumber(paymentSchedule) || paymentSchedule < 0) {
      errors.paymentSchedule = 'Payment Schedule is required.';
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };


  const handleSubmit = async () => {
    const loanData = {
      ...loanDetails,
      startDate: Date.parse(loanDetails.startDate),
      endDate: Date.parse(loanDetails.endDate),
    };

    // Validate loan details before submission
    if (!validateLoanDetails(loanData)) {
      setModalVisible(false)
    }

    setLoading(true); // Set loading state after validation

    try {
      const token = await auth.currentUser.getIdToken();
      const response = await axios.post(`${apiUrl}/loan/create`, loanData, {
        headers: {
          'Authorization': token
        },
        withCredentials: true
      })
        .then(
          response => {
            setLoanDetails({
              loanName: '',
              currency: 'USD',
              memo: '',
              principal: '',
              interestRate: '',
              startDate: '',
              endDate: '',
              compoundingFrequency: '',
              paymentSchedule: '',
            }
            )
            setValidationErrors({});
            setIsSubmissionComplete(true);
            setModalVisible(false);
            setLoading(false);
          })
    } catch (error) {
      // setErrorMessage(error.message || 'An error occurred');
      setErrorMessage('An error occurred while submitting the form. Please try again.');
      setLoading(false); // Clear loading state after error
    }
  };

  const softSubmit = async () => {
    setLoading(true);
    const originalLoanDetails = { // Store original loan details
      ...loanDetails,
    };
    const loanData = {
      ...loanDetails,
      // startDate: Date.parse(loanDetails.startDate),
      // endDate: Date.parse(loanDetails.endDate),
      interestRate: parseFloat(loanDetails.interestRate).toFixed(2), // Format after conversion
      principal: parseFloat(loanDetails.principal).toFixed(2),

      // Conversion and formatting for interestRate and principal
    };

    setLoanDetails(loanData);

    // this will do the math


    // Validate loan details before submission
    if (!validateLoanDetails(loanData)) {
      setLoanDetails(originalLoanDetails);
      setModalVisible(false)
    } else {
      setModalVisible(true);
    }

    setLoading(false);

  };

  function formatDisplayValue(value, type) {
    switch (type) {
      case 'principal':
        return `$${Number(value).toLocaleString()}`;
      case 'interest':
        return `${value}%`;
      default:
        return value;
    }
  }

  const handleCancel = () => {
    setModalVisible(false)
  };

  const calendarTheme = {
    backgroundColor: 'yellow',
    calendarBackground: '#0E402D',
    textSectionTitleColor: 'white',
    dayTextColor: '#749D47',
    todayTextColor: '#749D47',
    selectedDayBackgroundColor: '#0E402D',
    selectedDayTextColor: 'red',
    monthTextColor: 'white',
    indicatorColor: 'blue',
    textDayFontWeight: '800',
    textMonthFontWeight: 'bold',
    textDayHeaderFontWeight: '800',
    textDayFontSize: 16,
    textMonthFontSize: 16,
    textDayHeaderFontSize: 16
  };

  return (
    <View style={styles.canvas}>
      {loading ? <ModuleLoading /> : isSubmissionComplete ?
        <View style={styles.successLoanCard}>
          <Text style={styles.successText}>Loan Created Successfully!</Text>
        </View>
        : (
          !isSubmissionComplete &&
          <View style={styles.loanCard}>
            <Text style={styles.title}>Track a New Loan</Text>
            {errorMessage && <Text style={styles.errorText}>{errorMessage}</Text>}
            <Text style={styles.label}>Loan Name</Text>
            {validationErrors.loanName && <Text style={styles.warningText}>{validationErrors.loanName}</Text>}
            <TextInput
              style={styles.input}
              value={loanDetails.loanName}
              onChangeText={text => {
                if (text.length <= 32) {
                  setLoanDetails({ ...loanDetails, loanName: text })
                }
              }}
              placeholder="e.g. car loan from dad"
            />

            <Text style={styles.label}>Memo</Text>
            {validationErrors.memo && <Text style={styles.warningText}>{validationErrors.memo}</Text>}
            <TextInput
              style={styles.input}
              value={loanDetails.memo}
              onChangeText={text => {
                if (text.length <= 256) {
                  setLoanDetails({ ...loanDetails, memo: text })
                }
              }}
              placeholder=""
            />

            <Text style={styles.label}>Principal (USD)</Text>
            {validationErrors.principal && <Text style={styles.warningText}>{validationErrors.principal}</Text>}
            {/* TODO add hover info on each field, create function since all of them are the same...^v */}
            <TextInput
              style={styles.input}
              value={loanDetails.principal}
              onChangeText={text => {
                const numericValue = text.replace(/[^0-9.,]/g, ''); // remove non-numeric characters
                setLoanDetails({ ...loanDetails, principal: numericValue });
                // setDisplayPrincipal(`${Number(numericValue).toLocaleString()}`);
              }}
              placeholder=""
              keyboardType="decimal-pad"
            />

            <Text style={styles.label}>Interest Rate (%)</Text>
            {validationErrors.interestRate && <Text style={styles.warningText}>{validationErrors.interestRate}</Text>}
            <TextInput
              style={styles.input}
              value={loanDetails.interestRate}
              onChangeText={text => {
                const numericValue = text.replace(/[^0-9.]/g, ''); // remove non-numeric characters
                setLoanDetails({ ...loanDetails, interestRate: numericValue });
                // setDisplayInterestRate(`${numericValue}`);
              }}
              placeholder=""
              keyboardType="decimal-pad"
            />

            <Text style={styles.label}>Compounding Frequency</Text>
            {validationErrors.compoundingFrequency && <Text style={styles.warningText}>{validationErrors.compoundingFrequency}</Text>}
            <Text
              style={styles.input}
              onPress={() => setCompoundingFrequencyModalVisible(true)}>
              {selectedCompoundingFrequency || "Select a Frequency"}
            </Text>

            <Text style={styles.label}>Start Date</Text>
            {validationErrors.startDate && <Text style={styles.warningText}>{validationErrors.startDate}</Text>}
            {/* TODO make info buttons v */}
            <Text style={[styles.input, styles.datePickerTrigger]} onPress={() => setCalendarVisible(true)}>
              {loanDetails.startDate ? loanDetails.startDate : "Select Start Date"}
            </Text>
            {/* TODO make info buttons ^ */}

            <Text style={styles.label}>End Date</Text>
            {validationErrors.endDate && <Text style={styles.warningText}>{validationErrors.endDate}</Text>}
            <Text style={[styles.input, styles.datePickerTrigger]} onPress={() => setEndDateCalendarVisible(true)}>
              {loanDetails.endDate ? loanDetails.endDate : "Select End Date"}
            </Text>

            <Text style={styles.label}>Repayment Schedule</Text>
            {validationErrors.paymentSchedule && <Text style={styles.warningText}>{validationErrors.paymentSchedule}</Text>}
            <Text
              style={styles.input}
              onPress={() => setFrequencyModalVisible(true)}>
              {selectedFrequency || "Select a Frequency"}
            </Text>
            <Modal
              visible={isCalendarVisible}
              animationType="slide"
              transparent={true}
              onRequestClose={() => setCalendarVisible(false)}>
              <View style={styles.modalContainer}>
                <View style={styles.calendarContainer}>
                  <Calendar
                    renderArrow={direction => direction === 'left' ? <FontAwesome5 name="arrow-alt-circle-left" size={24} color="white" /> : <FontAwesome5 name="arrow-alt-circle-right" size={24} color="white" />}
                    theme={calendarTheme}
                    hideExtraDays={true}
                    onDayPress={(day) => {
                      const formattedDate = moment(day.dateString, 'YYYY-MM-DD').format('MM/DD/YYYY');
                      handleDateChange(formattedDate);
                    }}
                  />
                  <TouchableOpacity
                    style={styles.closeButton}
                    onPress={() => setCalendarVisible(false)}>
                    <Text style={styles.closeButtonText}>Close</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </Modal>


            <Modal
              visible={isFrequencyModalVisible}
              animationType="slide"
              transparent={true}
              onRequestClose={() => setFrequencyModalVisible(false)}>
              <View style={styles.modalContainer}>
                <View style={styles.calendarContainer}>
                  <Text style={styles.frequencytitle}>Repayment Schedule</Text>
                  {repaymentFrequencyOptions.map((option, index) => (
                    <TouchableOpacity
                      key={index} // Important for performance with lists
                      onPress={() => handleFrequencyChange(option.label)}>
                      <Text style={styles.frequencyOptionText}>{option.label}</Text>
                    </TouchableOpacity>
                  ))}
                  <TouchableOpacity
                    style={styles.closeButton}
                    onPress={() => setFrequencyModalVisible(false)}>
                    <Text style={styles.closeButtonText}>Close</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </Modal>

            <Modal
              visible={isCompoundingFrequencyModalVisible}
              animationType="slide"
              transparent={true}
              onRequestClose={() => setCompoundingFrequencyModalVisible(false)}>
              <View style={styles.modalContainer}>
                <View style={styles.calendarContainer}>
                  <Text style={styles.frequencytitle}>Compounding Frequency</Text>
                  {frequencyOptions.map((option, index) => (
                    <TouchableOpacity
                      key={index} // Important for performance with lists
                      onPress={() => handleCompoundFrequencyChange(option.label)}>
                      <Text style={styles.frequencyOptionText}>{option.label}</Text>
                    </TouchableOpacity>
                  ))}
                  <TouchableOpacity
                    style={styles.closeButton}
                    onPress={() => setCompoundingFrequencyModalVisible(false)}>
                    <Text style={styles.closeButtonText}>Close</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </Modal>

            <Modal
              visible={isEndDateCalendarVisible}
              animationType="slide"
              transparent={true}
              onRequestClose={() => setEndDateCalendarVisible(false)}>
              <View style={styles.modalContainer}>
                <View style={styles.calendarContainer}>
                  <Calendar
                    renderArrow={direction => direction === 'left' ? <FontAwesome5 name="arrow-alt-circle-left" size={24} color="white" /> : <FontAwesome5 name="arrow-alt-circle-right" size={24} color="white" />}
                    theme={calendarTheme}
                    onDayPress={(day) => {
                      const formattedDate = moment(day.dateString, 'YYYY-MM-DD').format('MM/DD/YYYY');
                      handleEndDateChange(formattedDate);
                    }}
                  />
                  <TouchableOpacity
                    style={styles.closeButton}
                    onPress={() => setEndDateCalendarVisible(false)}>
                    <Text style={styles.closeButtonText}>Close</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </Modal>

            <Modal
              visible={modalVisible}
              animationType="slide"
              transparent={true}
              onRequestClose={() => setFrequencyModalVisible(false)}
            >
              <ScrollView
                ref={scrollViewRef}
                contentContainerStyle={{ flexGrow: 1 }}
              >
                <View style={styles.modalCanvas}>
                  <View style={styles.loanCard}>
                    {/* <View style={styles.calcContainer}> */}
                    <Text style={styles.title}>Summary</Text>
                    {Object.entries(loanDetails).map(([label, value], index) => {
                      let displayValue = value;
                      let valueType = '';

                      if (label === 'paymentSchedule' || label === 'compoundingFrequency') {
                        const matchingOption = frequencyOptions.find(option => option.value === value);
                        if (matchingOption) {
                          displayValue = matchingOption.label;
                        } else {
                          displayValue = 'Invalid frequency';
                        }
                      }

                      if (label === 'principal') {
                        valueType = 'principal';
                      } else if (label === 'interestRate') {
                        valueType = 'interest';
                      }

                      // Split the camel cased label and join with a space
                      const formattedLabel = label.replace(/([A-Z])/g, ' $1').trim();

                      // Capitalize the first letter of each word
                      const capitalizedLabel = formattedLabel.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

                      return (
                        <View
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            backgroundColor: index % 2 === 0 ? 'rgba(256, 256, 256, 0.10)' : 'transparent',
                            borderRadius: index % 2 === 0 ? 12 : 0
                          }}
                          key={index}
                        >
                          <Text style={styles.modalLeft} numberOfLines={1} ellipsizeMode='tail'>{capitalizedLabel}</Text>
                          <Text style={styles.modalRight} numberOfLines={1} ellipsizeMode='tail'>{formatDisplayValue(displayValue, valueType)}</Text>
                        </View>
                      );
                    })}

                    <Text style={styles.modalTitle}>Calculations</Text>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 20 }}>
                      <Text style={styles.modalLeft} numberOfLines={1} ellipsizeMode='tail'>Monthly Payment</Text>
                      <Text style={styles.modalRight} numberOfLines={1} ellipsizeMode='tail'>zzz</Text>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', backgroundColor: 'rgba(256, 256, 256, 0.10)', borderRadius: 12 }}>
                      <Text style={styles.modalLeft} numberOfLines={1} ellipsizeMode='tail'>Number of Payments</Text>
                      <Text style={styles.modalRight} numberOfLines={1} ellipsizeMode='tail'>zzz</Text>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                      <Text style={styles.modalLeft} numberOfLines={1} ellipsizeMode='tail'>Total Interest</Text>
                      <Text style={styles.modalRight} numberOfLines={1} ellipsizeMode='tail'>zzz</Text>
                    </View>
                    {/* </View> */}
                    <View style={styles.buttonContainer}>
                      <TouchableOpacity style={styles.modalButton} onPress={handleSubmit}>
                        <Text style={styles.buttonText} numberOfLines={1} ellipsizeMode='tail'>Submit</Text>
                      </TouchableOpacity>
                      <TouchableOpacity style={styles.modalButton} onPress={handleCancel}>
                        <Text style={styles.buttonText} numberOfLines={1} ellipsizeMode='tail'>Cancel</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
                {/* </View> */}
                {/* </View> */}
              </ScrollView>
            </Modal>


            <TouchableOpacity style={styles.button} onPress={softSubmit}>
              <Text style={styles.buttonText} numberOfLines={1} ellipsizeMode='tail'>Submit</Text>
            </TouchableOpacity>
          </View>
        )}
    </View>
  );

};

export default LoanCreation;
