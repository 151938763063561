import { StyleSheet, Dimensions } from 'react-native';

export const styles = StyleSheet.create({
    container: {
        margin: "auto",
        width: "calc(100% - 18px)",
        maxWidth: 768,
        paddingHorizontal: 20,
        borderRadius: 12,
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        shadowColor: "#000",
        shadowOffset: {
            width: 3,
            height: 3,
        },
        shadowOpacity: 0.20,
        shadowRadius: 3.84,
        elevation: 1,
        marginBottom: 40,
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        color: 'white',
        width: '100%',
        maxWidth: 720,
        marginTop: 20,
        marginBottom: 20,
        margin: 'auto',
        textAlign: 'center',
    },
    loadingText: {
        fontSize: 16,
        color: 'white',
        textAlign: 'center',
    },
    row: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: 10,
        fontSize: 16,
        marginTop: 2,
        borderRadius: 12,
    },
    imageRow: {
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 16,
        borderRadius: 12,
        marginBottom: 20,
    },
    cellTitle: {
        color: 'white',
        flex: 1,
        textAlign: 'center',
        marginBottom: 20,
        fontSize: 20,
        fontWeight: 'bold',
        margin: "auto",
        textDecorationLine: 'none'
    },
    cellTime: {
        color: 'white',
        flex: 1,
        textAlign: 'center',
        fontSize: 16,
        fontStyle: 'italic',
        marginBottom: 20,
    },
    cellDescription: {
        color: 'white',
        fontSize: 16,
        fontWeight: '700',
        margin: "auto",
        marginBottom: 20,
        width: '100%',
        maxWidth: 600,
        backgroundColor: 'rgba(0, 0, 0, 0.10)',
        padding: 8,
        borderRadius: 12,
    },
    chunk: {
        flex: 1,
        color: 'white',
        fontSize: 14,
        textAlign: 'left',
        hypens: 'auto',
        whiteSpace: 'break-word',
    },
    cellReadMore: {
        color: 'white',
        fontSize: 16,
        fontWeight: 'bold',
        margin: "auto",
        marginBottom: 64,
    },
    buttonText: {
        color: 'white',
        fontSize: 16,
        fontWeight: '500',
    },
    buttonWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 64,
        width: '40%',
        maxWidth: 200,
        margin: 'auto',
        flex: 1,
    },
    finalButtonWrapper: {
        backgroundColor: 'rgba(256, 256, 256, 0.10)',
        alignItems: 'center',
        padding: 20,
        width: '100%',
        flex: 1,
        borderRadius: 12,
    },
    button: {
        backgroundColor: 'rgba(256, 256, 256, 0.10)',
        alignItems: 'center',
        padding: 20,
        width: '100%',
        height: 64,
        flex: 1,
        borderRadius: 12,
    },
    buttonGroup: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 20,
        width: '100%',
        maxWidth: 256,
        margin: 'auto',
    },
    articlesButtonGroup: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 40,
        width: '100%',
        maxWidth: 256,
        margin: 'auto',
    }
});
