import React, { useState, useEffect } from 'react';
import { Calendar } from 'react-native-calendars';
import { Text, View } from 'react-native';
import { styles } from './Styles';

const ToolCalendar = () => {
    const [markedDates, setMarkedDates] = useState({});

    useEffect(() => {
        const newMarkedDates = {};
        loans.forEach((loan) => {
            loan.payments.forEach((payment) => {
                const date = payment.date;
                newMarkedDates[date] = { marked: true, dotColor: 'green', activeOpacity: 0 };
            });
        });
        setMarkedDates(newMarkedDates);
    }, []);

    const loans = [
        {
            loanId: "123456789",
            payments: [
                { date: '2024-01-01' },
                { date: '2024-01-31' },
                { date: '2024-11-26' }
            ]
        }
    ];

    const DayComponent = ({ date, state }) => {
    let dayStyle = {
        ...styles.day,
    };

    let textStyle = {
        ...styles.dayText,
        color: state === 'today' ? '#FFFFFF' : 'white',
    };

    const today = new Date();
    const isToday = today.getDate() === date.day && today.getMonth() + 1 === date.month && today.getFullYear() === date.year;

    if (isToday) {
        textStyle.color = 'white';
        textStyle.fontWeight = 'bold';
        dayStyle.borderWidth = 2;
        dayStyle.borderColor = '#FFFFFF';
        dayStyle.borderRadius = 12;
    }

    return (
        <View style={dayStyle}>
            <Text style={textStyle}>{date.day}</Text>
        </View>
    );
};


    const calendarTheme = {
        calendarBackground: 'transparent',
        monthTextColor: 'white',
        textMonthFontWeight: 'bold',
        textMonthFontSize: 18,
        textDayHeaderFontSize: 14,
        textDayFontColor: 'red',
    };

    return (
        <View style={styles.container}>
            <Calendar
                theme={calendarTheme}
                dayComponent={DayComponent}
                markedDates={markedDates}
            />
        </View>
    );
};

export default ToolCalendar;
