import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'top',
    textAlign: 'center',
    height: "100%",
    padding: 20,
    backgroundImage: "linear-gradient(to top, #749D47, #0E402D )",
  },
  logo: {
    width: 300,
    height: 300,
    resizeMode: 'contain',
    margin: 'auto',
    textAlign: 'center',
  },
  input: {
    width: '100%',
    maxWidth: 400,
    marginVertical: 20,
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    padding: 24,
    alignItems: 'center',
  },
  forgotPasswordText: {
    color: 'white',
    marginTop: "32px",
    fontWeight: "bold",
    fontSize: 12,
  },
  button: {
    backgroundColor: 'rgba(256, 256, 256, 0.10)',
    alignItems: 'center',
    padding: 20,
    margin: 10,
    marginBottom: 10,
    marginTop: 0,
    flex: 1,
    borderRadius: 12,
    // boxShadow: "3px 3px 3.84px rgba(0, 0, 0, 0.20)",
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 16,
  },
  buttonGroup: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
    width: '100%',
    maxWidth: 256,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});
