import { initializeApp, getApps, getApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyCXMzS7Kf1YnXm_RmuYrJ2xRpNLEr1n_YM",
    authDomain: "bharo-398004.firebaseapp.com",
    databaseURL: "https://bharo-398004-default-rtdb.firebaseio.com",
    projectId: "bharo-398004",
    storageBucket: "bharo-398004.appspot.com",
    messagingSenderId: "342191046939",
    appId: "1:342191046939:web:1a3fece990939a14cf13be",
    measurementId: "G-0MSSEWB64F"
};

let app;
if (!getApps().length) {
    app = initializeApp(firebaseConfig);
} else {
    app = getApp();
}

const auth = getAuth(app);

export { auth };
