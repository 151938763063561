import React, { createContext, useContext, useState, useEffect } from 'react';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import axios from 'axios';
import { auth } from '../../firebase'; // Ensure the correct path

const AuthContext = createContext();

// TODO NOTE
// const AuthContext = createContext({
//   user: null,
//   loading: false,
//   signInWithGoogle: () => {},
//   hasNavigated: false, // Add hasNavigated to context
// });

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const apiUrl = 'https://api.bharo.org/api';

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      // Step 1: Request CSRF token
      const csrfResponse = await axios.get(`${apiUrl}/csrf-token`, { withCredentials: true });
      const csrfToken = csrfResponse.data.csrfToken;

      // Step 2: Proceed with Google sign-in
      const result = await signInWithPopup(auth, provider); // Use imported auth
      const idToken = await result.user.getIdToken();

      // Step 3: Send idToken and csrfToken to your backend
      await axios.post(`${apiUrl}/auth/google`, { idToken, csrfToken }, { withCredentials: true });

      setUser({
        uid: result.user.uid,
        name: result.user.displayName,
        email: result.user.email,
        phone: result.user.phoneNumber,
      });
      setLoading(false); // Set loading to false after user is set
    } catch (error) {
      console.error('Error signing in with Google:', error.message);
      setLoading(false); // Ensure loading is set to false on error
    }
  };

  const signOut = async () => {
    await auth.signOut(); // Use imported auth
    setUser(null);
    setLoading(false); // Set loading to false after sign out
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => { // Use imported auth
      if (user) {
        const idToken = await user.getIdToken();
        try {
          const response = await axios.post(`${apiUrl}/validateSession`, { idToken }, { withCredentials: true });
          setUser({
            uid: user.uid,
            name: user.displayName,
            email: user.email,
            phone: user.phoneNumber,
          });
        } catch (error) {
          () => {} // (gfys) TODO add error screen?
        }
      } else {
        setUser(null);
      }
      setLoading(false); // Ensure loading is set to false after checking auth state
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ user, signInWithGoogle, signOut, loading }}>
      {children}
    </AuthContext.Provider>
  );
};
