import React from 'react';
import { View, Text, TouchableOpacity, TextInput, Switch } from 'react-native';
import axios from 'axios';
import { useAuth } from '../AuthContext/Context';
import { styles } from './Styles';

const Settings = () => {
  const { user, signOut } = useAuth();

  const apiUrl = 'https://api.bharo.org/api'

  const handleSignOut = async () => {
    try {
      await axios.post(
        `${apiUrl}/auth/signout`,
        {},
        // { headers: { 'Bypass-Tunnel-Reminder': 'true' } }
      );
      await signOut();
    } catch (error) {
      console.error('Error during sign-out:', error);
    }
  };

  return (
    <View style={styles.canvas}>
      <View style={styles.loanCard}>
        <Text style={styles.managePermissionsTitle}>Contact Information</Text>
        <Text style={styles.label}>Email</Text>
        <TextInput
          style={styles.input}
          value={user?.email || ""}
          editable={false}
        />
        <View style={styles.buttonGroup}>
          <TouchableOpacity style={styles.button} onPress={handleSignOut}>
            <Text style={styles.buttonText}>Sign Out</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

export default Settings;
