import React from 'react';
import { View, StyleSheet } from 'react-native';
import { BarChart, Bar, XAxis, YAxis, Tooltip } from 'recharts';

const Chart = () => {
  const data = [
    { name: 'Jan', value: 50 },
    { name: 'Feb', value: 80 },
    { name: 'Mar', value: 65 },
    { name: 'Apr', value: 40 },
    { name: 'May', value: 75 },
  ];

  return (
    <View style={styles.container}>
      <BarChart width={730} height={250} data={data}>
        <XAxis dataKey="name" stroke="#8884d8" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="value" fill="#82ca9d" />
      </BarChart>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F5FCFF',
  },
});

export default Chart;
