import { StyleSheet, Dimensions } from 'react-native';

const windowWidth = Dimensions.get('window').width;

export const styles = StyleSheet.create({
  canvas: {
    backgroundColor: "#0E402D",
    backgroundImage: "linear-gradient(to top, #749D47, #0E402D )",
    height: "100%",
    overflowY: "auto",
    zIndex: "1",
    paddingTop: 40,
    paddingLeft: 31,
    paddingRight: 31,
    paddingBottom: 64,
  },
  loanCard: {
    margin: "auto",
    marginTop: 0,
    width: "calc(100% - 18px)",
    maxWidth: 768,
    padding: 20,
    paddingTop: 4,
    borderRadius: 12,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    shadowColor: "#000",
    shadowOffset: {
      width: 3,
      height: 3,
    },
    shadowOpacity: 0.20,
    shadowRadius: 3.84,
    elevation: 1,
    marginBottom: 40,
  },
  profileContainer: {
    alignItems: 'center',
    marginVertical: 20,
  },
  profileImage: {
    width: 100,
    height: 100,
    borderRadius: 100,
    marginBottom: 10,
    overflow: 'hidden',
    backgroundColor: '#FFC666',
  },
  profileName: {
    fontSize: 16,
    color: 'white',
    fontWeight: 'bold',
  },
  profileEmail: {
    color: 'white',
    margin: 10,
    fontWeight: 'bold',
    fontSize: 14,
  },
  profilePhone: {
    color: 'white',
    fontSize: 14,
  },
  managePermissionsTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: 'white',
    width: '100%',
    maxWidth: 720,
    margin: 'auto',
    marginTop: 20,
    marginBottom: 20,
    textAlign: 'center',
  },
  permissionItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 20,
    width: '100%',
  },
  permissionLabel: {
    color: 'white',
    fontSize: 14,
  },
  label: {
    fontSize: 14,
    marginBottom: 5,
    color: 'white',
  },
  button: {
    backgroundColor: 'rgba(256, 256, 256, 0.10)',
    padding: 20,
    alignItems: 'center',
    width: '100%',
    maxWidth: 256,
    flex: 1,
    borderRadius: 12,
    margin: 'auto',
  },
  firstButton: {
    backgroundColor: 'rgba(256, 256, 256, 0.10)',
    padding: 20,
    alignItems: 'center',
    marginBottom: 64,
    width: '100%',
    flex: 1,
    borderRadius: 12,
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 14,
  },
  buttonGroup: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: "auto",
    marginTop: 10,
    width: '100%',
  },
  input: {
    display: 'flex',
    alignItems: 'center',
    height: 40,
    borderColor: 'white',
    borderWidth: 0,
    marginBottom: 20,
    padding: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.10)',
    color: 'white',
    fontWeight: '400',
    fontSize: 16,
    borderRadius: 12,
  },
});
