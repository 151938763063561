import { View, Text, TouchableOpacity, Image, Linking } from 'react-native';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useNavigation } from '@react-navigation/native';
import { styles } from './Styles';
import { LoadingScreen } from '../Loading/Loading';
import React, { useState } from 'react';
import { auth } from '../../firebase';
import axios from 'axios';

const AuthenticationScreen = () => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);

  const apiUrl = 'https://api.bharo.org/api';

  const googleSignIn = async () => {
    setLoading(true);

    try {
      // Step 1: Request CSRF token
      const csrfResponse = await axios.get(`${apiUrl}/csrf-token`, { withCredentials: true });
      const csrfToken = csrfResponse.data.csrfToken;

      // Step 2: Proceed with Google sign-in
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({ prompt: 'select_account' });
      const result = await signInWithPopup(auth, provider);
      const token = await result.user.getIdToken();

      await axios.post(`${apiUrl}/auth/google`, { idToken: token, csrfToken }, { withCredentials: true })
        .then(response => {
          window.location.reload();
        })

    } catch (error) {
      console.error('Error during Google sign-in:', error.message);
    } finally {
      setLoading(false);
    }
  };


  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <View style={styles.container}>
      <View>
        <Image
          source={require("../../assets/Logo/SVG/main-logo-transparent.svg")}
          style={styles.logo}
        />
      </View>
      <View style={styles.buttonGroup}>
        <TouchableOpacity style={styles.button} onPress={googleSignIn}>
          <Text style={styles.buttonText}>Log In With Google</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default AuthenticationScreen;
