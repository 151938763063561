import './suppressWarnings';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationContainer, useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import AuthenticationScreen from './components/Landing/Landing';
import LoanCreation from './components/LoanC/LoanC';
import LoanOverview from './components/LoanOverview/LoanOverview';
import Settings from './components/Settings/Settings';
import ControlPanel from './components/Dashboard/Bharo';
import LandingScreen from './components/AuthLogin/Login';
import { AuthProvider, useAuth } from './components/AuthContext/Context';
import { LoadingScreen } from './components/Loading/Loading';
import React from 'react';
import { Ionicons } from '@expo/vector-icons';
import MetaTags from './components/ToolMetaTags/MetaTags';
import { TouchableOpacity, StyleSheet, Text, View, Linking } from 'react-native';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

const AppContent = () => {
    const navigation = useNavigation();
    const { user, loading: authLoading } = useAuth();
    const iconColor = '#FFC666'
    const iconSize = 32
    const barIconSpacing = 20

    if (authLoading) {
        return (
            <>
                <Stack.Navigator>
                    <Stack.Screen
                        name="Loading"
                        component={LoadingScreen}
                        options={{ title: 'Loading...', headerShown: false }}
                    />
                </Stack.Navigator>
            </>
        );
    }

    return (
        <>
            <MetaTags
                title="Bharo Lending App"
                description="Personalized loan tracking at your fingertips. Bharo from your support network and start building generational wealth today."
                image={require("./assets/Logo/PNG/main-logo.png")}
                url="https://app.bharo.org"
            />
            <>
                {user ? (
                    // this shows the name of the Tab.Screen's at the top and controls top nav styling
                    <Tab.Navigator
                        initialRouteName="Loan Details"
                        screenOptions={({ route }) => ({
                            headerTintColor: 'white',
                            headerStyle: {
                                backgroundColor: "#0E402D",
                                borderBottomWidth: 0,
                                boxShadow: "3px 3px 3.84px rgba(0, 0, 0, 0.20)",
                            },
                            tabBarStyle: {
                                backgroundColor: "#0E402D",
                                height: 64,
                                padding: 8,
                                border: "none",
                                alignItems: 'center', // this centers the bottom bar buttons
                                justifyContent: 'center',
                                boxShadow: "0px -3px 3.84px rgba(0, 0, 0, 0.20)", // shadow above with no offset
                            },
                            contentStyle: {
                                backgroundColor: "#0E402D",
                                backgroundImage: "linear-gradient(to top, #749D47, #0E402D )",
                            },
                            // headerLeft: route.name === 'Bharo' ? null : () => <BackButton />, // Hide back button only for "Bharo"
                            headerRight: route.name === "Home" ?
                                () => (
                                    <View style={{ flexDirection: 'row' }}>
                                        <View style={styles.topButtonStyle}><TouchableOpacity onPress={() => {
                                            navigation.navigate("Settings")
                                        }} style={styles.backButton}>
                                            <Ionicons name="person-circle" size={iconSize} color="white" />
                                        </TouchableOpacity>
                                        </View>
                                        <View style={styles.topButtonStyle}><TouchableOpacity onPress={() => {
                                            console.log("Bharo Help")
                                        }} style={styles.backButton}>
                                            <Ionicons name="help-circle" size={iconSize} color="white" />
                                        </TouchableOpacity>
                                        </View>
                                    </View>
                                )
                                :
                                route.name === "Loan Details" ?
                                    () => (
                                        <View style={{ flexDirection: 'row' }}>
                                            <View style={styles.topButtonStyle}><TouchableOpacity onPress={() => {
                                                navigation.navigate("Loan Creation")
                                            }} style={styles.backButton}>
                                                <Ionicons name="add-circle" size={iconSize} color="white" />
                                            </TouchableOpacity>
                                            </View>
                                            <View style={styles.topButtonStyle}><TouchableOpacity onPress={() => {
                                                console.log("Loan Tracking Help")
                                            }} style={styles.backButton}>
                                                <Ionicons name="help-circle" size={iconSize} color="white" />
                                            </TouchableOpacity>
                                            </View>
                                        </View>
                                    )
                                    :
                                    route.name === "Loan Creation" ?
                                        () => (
                                            <View style={{ flexDirection: 'row' }}>
                                                <View style={styles.topButtonStyle}><TouchableOpacity onPress={() => {
                                                    console.log("Loan Creation Help")
                                                }} style={styles.backButton}>
                                                    <Ionicons name="help-circle" size={iconSize} color="white" />
                                                </TouchableOpacity>
                                                </View>
                                            </View>
                                        )
                                        :
                                        ""
                        })}
                    // change for easier testing...
                    >
                        <Tab.Screen
                            name="Home"
                            component={ControlPanel}
                            options={{
                                tabBarLabel: ({ focused }) => (

                                    <Text
                                        style={{
                                            paddingTop: 5,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Ionicons name="hourglass" color={focused ? iconColor : 'white'} style={{ marginLeft: 4 }} size={iconSize} />
                                        <Text style={{ fontSize: 10, color: focused ? iconColor : 'white', marginLeft: 3 }}>
                                            Home
                                        </Text>
                                    </Text>
                                ),
                                headerTitle: 'Bharo', // changes top text
                                tabBarLabelPosition: 'below-icon',
                                tabBarLabelStyle: {
                                    fontSize: 10,
                                    color: 'white'
                                },
                                tabBarItemStyle: {
                                    marginHorizontal: barIconSpacing, // adjust this value to increase or decrease the margin
                                },
                                tabBarIcon: () => (
                                    null
                                ),
                            }}
                        />
                        <Tab.Screen
                            name="Loan Details"
                            component={LoanOverview}
                            options={{
                                tabBarLabel: ({ focused }) => (
                                    <Text
                                        style={{
                                            paddingTop: 5,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Ionicons name="list-circle" color={focused ? iconColor : 'white'} style={{ marginLeft: 4 }} size={iconSize} />
                                        <Text style={{ fontSize: 10, color: focused ? iconColor : 'white', marginLeft: 0 }}>
                                            Track
                                        </Text>
                                    </Text>
                                ),
                                headerTitle: 'Track', // changes top text
                                tabBarLabelPosition: 'below-icon',
                                tabBarLabelStyle: {
                                    fontSize: 10,
                                    color: 'white'
                                },
                                tabBarItemStyle: {
                                    marginHorizontal: barIconSpacing, // adjust this value to increase or decrease the margin
                                },
                                tabBarIcon: () => (
                                    null
                                ),
                            }}
                        />
                        <Tab.Screen
                            name="Loan Creation"
                            component={LoanCreation}
                            // options={createScreenOptions("Details")}
                            options={{
                                tabBarLabel: ({ focused }) => (
                                    <Text
                                        style={{
                                            paddingTop: 5,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Ionicons name="add-circle" color={focused ? iconColor : 'white'} style={{ marginLeft: 4 }} size={iconSize} />
                                        <Text style={{ fontSize: 10, color: focused ? iconColor : 'white', marginLeft: 2 }}>
                                            New
                                        </Text>
                                    </Text>
                                ),
                                headerTitle: 'New Loan', // changes top text
                                tabBarLabelPosition: 'below-icon',
                                tabBarLabelStyle: {
                                    fontSize: 10,
                                    color: 'white'
                                },
                                tabBarItemStyle: {
                                    marginHorizontal: barIconSpacing, // adjust this value to increase or decrease the margin
                                },
                                tabBarIcon: () => (
                                    null
                                ),
                            }}
                        />

                        <Tab.Screen
                            name="TLDBlog"
                            component={() => null}
                            options={{
                                tabBarButton: (props) => (
                                    <TouchableOpacity {...props} onPress={() => Linking.openURL('https://bharo.org/financial-learning-hub')} />
                                ),
                                tabBarLabel: ({ focused }) => (
                                    <Text
                                        style={{
                                            paddingTop: 5,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Ionicons name="navigate-circle" color={focused ? iconColor : 'white'} style={{ marginLeft: 4 }} size={iconSize} />
                                        <Text style={{ fontSize: 10, color: focused ? iconColor : 'white', marginLeft: 4 }}>
                                            Blog
                                        </Text>
                                    </Text>
                                ),
                                tabBarLabelPosition: 'below-icon',
                                tabBarLabelStyle: {
                                    fontSize: 10,
                                    color: 'white'
                                },
                                tabBarItemStyle: {
                                    marginHorizontal: barIconSpacing,
                                },
                                tabBarIcon: () => (
                                    null
                                ),
                            }}
                        />

                        <Tab.Screen
                            name="Settings"
                            component={Settings}
                            options={{
                                tabBarIcon: () => null,
                                tabBarLabel: () => null,
                            }}
                        />

                    </Tab.Navigator>
                ) : (
                    <Stack.Navigator
                        screenOptions={{
                            headerTintColor: 'white',
                            headerStyle: {
                                backgroundColor: "#0E402D",
                                borderBottomWidth: 0,
                                padding: 100,
                            }
                        }}
                        initialRouteName="Welcome"
                    >
                        <Stack.Screen
                            options={{
                                headerShown: false,
                            }}
                            name="Welcome"
                            component={LandingScreen}
                        />
                        <Stack.Screen
                            name="Log In"
                            component={AuthenticationScreen}
                            initialParams={{ isAuth: !!user }}
                        />
                        <Stack.Screen
                            name="Bharo"
                            component={ControlPanel}
                        />
                    </Stack.Navigator>
                )
                }
            </>
        </>
    );
};

export default function App() {
    return (
        <>
            <AuthProvider>
                <NavigationContainer>
                    <AppContent />
                </NavigationContainer>
            </AuthProvider>
        </>
    );
}

const styles = StyleSheet.create({
    button: {
        flex: 1,
        borderRadius: 10,
        paddingLeft: 10,
        paddingRight: 10,
        marginLeft: 10,
        marginRight: 10,
        justifyContent: 'center',
        alignItems: 'center',
        width: 72,
        height: 32,
        backgroundColor: 'rgba(256, 256, 256, 0.10)',
    },
    backButton: {
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "center",
    },
    canvas: {
        backgroundColor: "#0E402D",
        backgroundImage: "linear-gradient(to top, #749D47, #0E402D )",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 9999,
        width: "100vw",
        position: 'absolute',
    },
    text: {
        color: 'white',
        fontSize: 14,
        // fontWeight: '700',
    },
    selected: {
        backgroundColor: 'rgba(0, 0, 0, 0.10)',
    },
    topButtonStyle: {
        flexDirection: "row",
        alignItems: "center",
        marginRight: 10,
        marginLeft: 12,
    },
});
