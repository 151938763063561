import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
    container: {
        margin: "auto",
        width: "calc(100% - 18px)",
        maxWidth: 768,
        padding: 20,
        borderRadius: 12,
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        shadowColor: "#000",
        shadowOffset: {
            width: 3,
            height: 3,
        },
        shadowOpacity: 0.20,
        shadowRadius: 3.84,
        elevation: 1,
        marginBottom: 40,
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        color: 'white',
        width: '100%',
        maxWidth: 720,
        marginBottom: 8,
        margin: 'auto',
        textAlign: 'center',
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 10,
        fontSize: 16,
        marginTop: 2,
        borderRadius: 12,
    },
    cell: {
        color: 'white',
        flex: 1,
        textAlign: 'center',
        fontSize: 14,
    },
    nameCell: {
        color: 'white',
        flex: 1,
        textAlign: 'left',
        fontSize: 14,
        paddingLeft: 10,
    },
    moneyCell: {
        color: 'white',
        flex: 1,
        textAlign: 'right',
        fontSize: 14,
        paddingLeft: 10,
    },
    headerCell: {
        color: 'white',
        flex: 1,
        textAlign: 'center',
    },
    sortButton: {
        flex: 1,
    },
    sortButtonText: {
        color: 'white',
        fontSize: 14
    },
    sortButtonContent: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
});
