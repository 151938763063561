import React, { useState, useEffect, useCallback } from 'react';
import { View, Text, Dimensions, TouchableOpacity } from 'react-native';
import { styles } from './Styles';
import { auth } from '../../firebase';
import axios from 'axios';
import { ModuleLoading } from '../Loading/Loading'; // Import the loading component
import { useFocusEffect } from '@react-navigation/native'; // Import useFocusEffect
import { FontAwesome5 } from '@expo/vector-icons';
import ToolCalendar from '../ToolCalendar/Calendar';
import Chart from '../LoanOCharts/LoanOCharts';

const initialData = [
  { name: "zz", duedate: '18471600981', payment: 34.00, },
  { name: "dad", duedate: '328724181', payment: 900000000.00, },
  { name: "WWWWWWWW", duedate: '1716681600', payment: 1222.24, },
];

const LoanOverview = () => {
  const [data, setData] = useState(initialData);
  const [sortConfig, setSortConfig] = useState(null);
  const [numColumns, setNumColumns] = useState(1);
  const [loansArray, setLoansArray] = useState([]); // State to store fetched loans
  const [loading, setLoading] = useState(false); // State to manage loading state

  const apiUrl = 'https://api.bharo.org/api';

  const fetchLoans = async () => {
    setLoading(true); // Set loading to true before fetching loans
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await axios.post(`${apiUrl}/loan/read`, {}, {
        headers: { 'Authorization': token },
        withCredentials: true,
      });
      setLoansArray(response.data.data.loan); // Update state with fetched loans
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Set loading to false after fetching loans
    }
  };

  useFocusEffect(
    useCallback(() => {
      fetchLoans(); // Fetch loans when the screen is focused
    }, [])
  );

  useEffect(() => {
    const updateLayout = () => {
      const windowWidth = Dimensions.get('window').width;
      let columns;
      if (windowWidth < 650) {
        columns = 2;
      } else if (windowWidth < 1200) {
        columns = 3;
      } else {
        columns = 3;
      }
      setNumColumns(columns);
    };

    Dimensions.addEventListener('change', updateLayout);
    updateLayout();

    return () => Dimensions.removeEventListener('change', updateLayout);
  }, []);

  const handlePayment = (loanId) => {
    alert(`Payment for loan ${loanId} marked as completed.`);
  };

  const handleEdit = (loanId) => {
    alert(`Edit loan ${loanId}.`);
  };

  const sortData = (key) => {
    let sortedData = [];
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    sortedData = data.sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    setSortConfig({ key, direction });
    setData([...sortedData]);
  };

  const SortButton = ({ text, sortKey, onSort }) => {
    return (
      <TouchableOpacity onPress={() => onSort(sortKey)} style={styles.sortButton}>
        <View style={styles.sortButtonContent}>
          <FontAwesome5 name="sort" size={12} color="white" />
          <Text style={styles.sortButtonText}>{text}</Text>
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <View style={styles.canvas}>
      {loading ? (
        <ModuleLoading /> // Show loading component if data is being fetched
      ) : (
        <>
          {/* <ToolCalendar /> */}
          {/* <View style={styles.loanCard}>
            <Text style={styles.upcomingTitle}>Upcoming Payments</Text>
            <View style={styles.row}>
              <SortButton text="&nbsp;Due" sortKey="duedate" onSort={sortData} />
              <SortButton text="&nbsp;Name" sortKey="name" onSort={sortData} />
              <SortButton text="&nbsp;Amount" sortKey="payment" onSort={sortData} />
            </View>
            {data.map((entry, index) => (
              <View
                key={index}
                style={[
                  styles.row,
                  { backgroundColor: index % 2 === 1 ? 'transparent' : 'rgba(256, 256, 256, 0.10)' },
                ]}
              >
                <Text style={styles.cell}>{new Date(entry.duedate * 1000).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })}</Text>
                <Text style={styles.nameCell} numberOfLines={1} ellipsizeMode='tail' >{entry.name}</Text>
                <Text style={styles.moneyCell} numberOfLines={1} ellipsizeMode='tail' >
                  ${entry.payment.toFixed(2)}
                </Text>
              </View>
            ))}
          </View> */}
          <View style={styles.loanCard}>
            <Text style={styles.title}>Loan Details</Text>
            {/* {loansArray.length > 0 ? ( // Render list only if loans are available
            <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            {loansArray.map((item, index) => (
              <View key={index} style={styles.loanCard}>
              <Text style={styles.managePermissionsTitle} numberOfLines={1} ellipsizeMode='tail'>{item.loanName}</Text>
              <Text style={styles.input} numberOfLines={1} ellipsizeMode='tail'>Principal: ${item.principal}</Text>
              <Text style={styles.input} numberOfLines={1} ellipsizeMode='tail'>Interest Rate: {item.interestRate}%</Text>
              <Text style={styles.input} numberOfLines={1} ellipsizeMode='tail'>Start Date: {new Date(item.startDate * 1000).toLocaleDateString()}</Text>
              <Text style={styles.input} numberOfLines={1} ellipsizeMode='tail'>End Date: {new Date(item.endDate * 1000).toLocaleDateString()}</Text>
              <Text style={styles.input} numberOfLines={1} ellipsizeMode='tail'>Payment Schedule: {item.paymentSchedule}</Text>
              <Text style={styles.input} numberOfLines={1} ellipsizeMode='tail'>Status: {item.status}</Text>
              <Text style={styles.input} numberOfLines={1} ellipsizeMode='tail'>Memo: {item.memo}</Text>
              <View style={styles.buttonGroup}>
              <TouchableOpacity style={styles.button} onPress={() => handlePayment(item.loanId)}>
              <Text style={styles.buttonText}>Mark as Paid</Text>
              </TouchableOpacity>
              </View>
              <View style={styles.buttonGroup}>
              <TouchableOpacity style={styles.button} onPress={() => handleEdit(item.loanId)}>
              <Text style={styles.buttonText}>Edit</Text>
              </TouchableOpacity>
              </View>
              </View>
            ))}
            </View>
          ) : (
            <View style={styles.errorCard}>
            <Text style={styles.errorText}>No loan data available. </Text>
            <Text style={styles.errorText}>Create a new loan below to start tracking!</Text>
            </View>
          )} */}
            <View style={styles.row}>
              <SortButton text="&nbsp;Due" sortKey="duedate" onSort={sortData} />
              <SortButton text="&nbsp;Name" sortKey="name" onSort={sortData} />
              <SortButton text="&nbsp;Amount" sortKey="payment" onSort={sortData} />
            </View>
            {data.map((entry, index) => (
              <View
                key={index}
                style={[
                  styles.row,
                  { backgroundColor: index % 2 === 1 ? 'transparent' : 'rgba(256, 256, 256, 0.10)' },
                ]}
              >
                <Text style={styles.cell}>{new Date(entry.duedate * 1000).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })}</Text>
                <Text style={styles.nameCell} numberOfLines={1} ellipsizeMode='tail' >{entry.name}</Text>
                <Text style={styles.moneyCell} numberOfLines={1} ellipsizeMode='tail' >
                  ${entry.payment.toFixed(2)}
                  {/* {entry.payment > 99999 ? `$${Math.floor(entry.payment / Math.pow(10, Math.floor(Math.log10(entry.payment)) - 4))}..` : `$${entry.payment.toFixed(2)}`} */}
                </Text>
              </View>
            ))}
          </View>
          <View style={styles.loanCard}>
            <Chart />
          </View>
        </>
      )}
    </View>
  );
};

export default LoanOverview;
